import { createApp } from 'vue';
import useAuth from '@/providers/auth';
import App from '@/App.vue'
import router from "@/router";
import filters from "@/filters/filters";

import 'intl-tel-input/build/css/intlTelInput.css';
import '@vueform/toggle/themes/default.css';
import Maska from "maska";

const { authCheck } = useAuth();

// @ts-ignore
const app = createApp(App);

//Filters
app.config.globalProperties.$filters = filters;

//Auth
authCheck().then(() => {
    app.use(router);
    return router.isReady();
});
//Mascaras
app.use(Maska);

//Mount
router.isReady().then(() => {
    app.mount('#app');
});