<template>
  <Loading :state="loading"></Loading>
  <router-view v-if="init"></router-view>
</template>
<script>
import Loading from "@/components/Loading";
import {reactive, provide, defineComponent, watchEffect} from "vue";
import useAuth from "@/providers/auth";
export default defineComponent({
  components: {Loading},
  setup(){
    const {init} = useAuth();

    const loading = reactive({
      active: false,
      message: 'Cargando...'
    });

    watchEffect(() => {
      loading.active = !init.value;
    });

    provide('loading', loading);

    return { loading, init }
  }
});
</script>
<style src="@/assets/css/global.css">

</style>