export const API_URL = 'https://api.gasoil.tekoserver.com/api/';
//export const API_URL = 'http://localhost/gasoil/api/';
export const APP_URL = 'https://gasoil.tekoserver.com';
export const APP_PREFIX = 'gasoil';

export const USER_ROLES = [
    {role: 'administrador', label: 'Administrador'},
    {role: 'operaciones', label: 'Operaciones'},
    {role: 'coordinador', label: 'Coordinador'},
    {role: 'supervisor', label: 'Supervisor'},
    {role: 'cliente', label: 'Cliente'}
];