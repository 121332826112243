import { createWebHistory, createRouter, RouterView } from "vue-router";
import useAuth from '@/providers/auth';

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/sistema',
        name: 'Sistema',
        component: RouterView,
        meta: {
            auth: true
        },
        children: [
            {
                path: 'inicio',
                component: () => import('@/views/sistema/Resumen.vue'),
                alias: '/sistema',
                name: 'Resumen'
            },
            {
                path: 'editar-cuenta',
                component: () => import('@/views/sistema/Perfil.vue'),
                name: 'Perfil'
            },
            {
                path: 'empleados',
                name: 'Empleados',
                component: RouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/views/sistema/empleados/Empleados.vue')
                    },
                    {
                        path: 'nuevo',
                        component: () => import('@/views/sistema/empleados/Nuevo.vue')
                    },
                    {
                        path: 'editar/:id_empleado',
                        component: () => import('@/views/sistema/empleados/Editar.vue')
                    },
                ],
            },
            {
                path: 'servicios',
                name: 'Servicios',
                meta: {
                    roles: ['superadmin', 'administrador']
                },
                component: RouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/views/sistema/servicios/Servicios.vue')
                    },
                    {
                        path: 'nuevo',
                        component: () => import('@/views/sistema/servicios/Nuevo.vue')
                    },
                    {
                        path: 'editar/:id_servicio',
                        component: () => import('@/views/sistema/servicios/Editar.vue')
                    },
                ]
            },
            {
                path: 'usuarios',
                name: 'Usuarios',
                meta: {
                    roles: ['superadmin', 'administrador']
                },
                component: RouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/views/sistema/usuarios/Usuarios.vue')
                    },
                    {
                        path: 'nuevo',
                        component: () => import('@/views/sistema/usuarios/Nuevo.vue')
                    },
                    {
                        path: 'editar/:id_user',
                        component: () => import('@/views/sistema/usuarios/Editar.vue')
                    },
                ]
            }
        ]
    },
    {
        path: "/:catchAll(.*)",
        component: () => import('@/views/P404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

const { user, token } = useAuth();

router.beforeEach((to, from, next) => {
    if(to.meta.auth && (!user.value || token.value === undefined)) {
        next({name: 'Login', replace: true});
    } else if (to.name === 'Login' && user.value) {
        next({name: 'Resumen', replace: true});
    } else {
        if(to.meta.roles){
            if(to.meta.roles.includes(user.value.role)){
                next();
            } else {
                next('/404');
            }
        } else {
            next();
        }
    }
});

export default router;